
<template>

<div class="row tgju-widgets-row" v-if="stock_overview && stock_overview.info.market != 'index'">
    <slot v-if="widgetLoad">
        <div class="text-center p-2 w100-w">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>
        <div class="market-profile-inner">
            <div class="profile-inner-filter-row">
                <div class="filter-row-name">
                    <i class="fa fa-clock-o" aria-hidden="true"></i> {{stock_overview.info.time}}
                </div>
            </div>
            <ul class="m-pointer" style="margin-bottom: 0;">
                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">خصیصه</div>
                        <div class="profile-inner-table-value">مقدار</div>
                        <!-- <div class="profile-inner-table-actions actions-min-w"></div> -->
                    </div>
                </li>

                <li class="active-current">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">آخرین معامله</div>
                        <div class="profile-inner-table-value"></div>
                        <!-- <div class="profile-inner-table-actions actions-min-w"></div> -->
                    </div>
                    <div class="profile-inner-table-box profile-inner-table-box-full">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">بازگشایی</div>
                                <div class="profile-inner-table-value" data-col="info.PriceFirst"  data-val="2164">{{ formatPrice(stock_overview.info.PriceFirst) }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">پایانی</div>
                                <div class="profile-inner-table-value" data-col="info.last_price.PClosing" data-val="2158">{{ formatPrice(stock_overview.info.PriceFirst) }} <span style="font-size: 12px;color: #666;">{{ stock_overview.info.last_price.closing_change }}</span></div>
                            </div>
                        </div>

                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">بیشترین</div>
                                <div class="profile-inner-table-value high" data-col="info.day_range.PriceMax">{{ formatPrice(stock_overview.info.day_range.PriceMax) }}</div>
                            </div>
                        </div>

                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کمترین</div>
                                <div class="profile-inner-table-value low" data-col="info.day_range.PriceMax">{{ formatPrice(stock_overview.info.day_range.PriceMin) }}</div>
                            </div>
                        </div>

                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">حجم</div>
                                <div class="profile-inner-table-value" data-col="trades.QTotTran5J">{{ roundInt(stock_overview.trades.QTotTran5J) }} <span style="font-size: 12px;color: #666;">{{ intToString(stock_overview.trades.QTotTran5J)}}</span></div>
                            </div>
                        </div>

                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دفعات</div>
                                <div class="profile-inner-table-value" data-col="trades.ZTotTran">{{ formatPrice(stock_overview.trades.ZTotTran) }}</div>
                            </div>
                        </div>
                        
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">حد قیمت</div>
                                <div class="profile-inner-table-value" data-col="info.gheymat_mojaz.PSGelStaMin">{{ formatPrice(stock_overview.info.gheymat_mojaz.PSGelStaMax) }} - {{ formatPrice(stock_overview.info.gheymat_mojaz.PSGelStaMin) }}</div>
                            </div>
                        </div>  
                        
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">ارزش</div>
                                <div class="profile-inner-table-value" data-col="trades.QTotCap">{{ roundInt(stock_overview.trades.QTotCap) }} <span style="font-size: 12px;color: #666;">{{ intToString(stock_overview.trades.QTotCap)}}</span></div>
                            </div>
                        </div>

                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">حجم مبنا</div>
                                <div class="profile-inner-table-value" data-col="trades.QTotCap">{{ roundInt(stock_overview.info.BaseVol) }} <span style="font-size: 12px;color: #666;">{{ intToString(stock_overview.info.BaseVol)}}</span></div>
                            </div>
                        </div>

                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">حد حجم</div>
                                <div class="profile-inner-table-value" data-col="trades.QTotCap">{{ formatPrice(stock_overview.etc.range_hajm_max)}} - {{ formatPrice(stock_overview.etc.range_hajm_min) }}</div>
                            </div>
                        </div>  
                        
                        
                    </div>
                </li>

                <li class="li-head" style="border-top: 0;">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">معاملات</div>
                        <div class="profile-inner-table-actions actions-min-w"></div>
                    </div>
                </li>

                <li :class="activeLi == 101 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(101)">
                        <div class="profile-inner-table-name">حقیقی</div>
                        <div class="profile-inner-table-value"></div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">تعداد خریدار</div>
                                <div class="profile-inner-table-value" data-col="trades.buy_and_sell.Buy_CountI">{{ formatPrice(stock_overview.trades.buy_and_sell.Buy_CountI) }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">حجم خرید</div>
                                <div class="profile-inner-table-value" data-col="trades.buy_and_sell.Buy_I_Volume">{{ roundInt(stock_overview.trades.buy_and_sell.Buy_I_Volume) }} <span style="font-size: 12px;color: #666;">{{ intToString(stock_overview.trades.buy_and_sell.Buy_I_Volume) }}</span></div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">تعداد فروشنده</div>
                                <div class="profile-inner-table-value" data-col="trades.buy_and_sell.Sell_CountI">{{ formatPrice(stock_overview.trades.buy_and_sell.Sell_CountI) }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">حجم فروش</div>
                                <div class="profile-inner-table-value" data-col="trades.buy_and_sell.Sell_I_Volume">{{ roundInt(stock_overview.trades.buy_and_sell.Sell_I_Volume) }} <span style="font-size: 12px;color: #666;">{{ intToString(stock_overview.trades.buy_and_sell.Sell_I_Volume) }}</span></div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">تغییر مالکیت</div>
                                <div class="profile-inner-table-value" data-col="etc.taghir_malekiat_haghighi"><div style="display: inline-block;direction: ltr;" :class="'profile-inner-table-value' + ( getNumMinus(stock_overview.etc.taghir_malekiat_haghighi) ? ' low': '')">{{ roundInt(stock_overview.etc.taghir_malekiat_haghighi) }}</div> <span style="font-size: 12px;color: #666;">{{ intToString(stock_overview.etc.taghir_malekiat_haghighi) }}</span></div>
                            </div>
                        </div>
                    </div>
                </li>

                <li :class="activeLi == 102 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(102)">
                        <div class="profile-inner-table-name">حقوقی</div>
                        <div class="profile-inner-table-value"></div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>

                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">تعداد خریدار</div>
                                <div class="profile-inner-table-value" data-col="trades.buy_and_sell.Buy_CountN">{{ formatPrice(stock_overview.trades.buy_and_sell.Buy_CountN) }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">حجم خرید</div>
                                <div class="profile-inner-table-value" data-col="trades.buy_and_sell.Buy_N_Volume">{{ roundInt(stock_overview.trades.buy_and_sell.Buy_N_Volume) }} <span style="font-size: 12px;color: #666;">{{ intToString(stock_overview.trades.buy_and_sell.Buy_N_Volume) }}</span></div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">تعداد فروشنده</div>
                                <div class="profile-inner-table-value" data-col="trades.buy_and_sell.Sell_CountN">{{ formatPrice(stock_overview.trades.buy_and_sell.Sell_CountN) }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">حجم فروش</div>
                                <div class="profile-inner-table-value" data-col="trades.buy_and_sell.Sell_N_Volume">{{ roundInt(stock_overview.trades.buy_and_sell.Sell_N_Volume) }} <span style="font-size: 12px;color: #666;">{{ intToString(stock_overview.trades.buy_and_sell.Sell_N_Volume) }}</span></div>
                            </div>
                        </div> 
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">تغییر مالکیت</div>
                                <div class="profile-inner-table-value" data-col="etc.taghir_malekiat_haghighi"><div style="display: inline-block;direction: ltr;" :class="'profile-inner-table-value' + ( getNumMinus(stock_overview.etc.taghir_malekiat_hoghoghi) ? ' low': ' ')">{{ roundInt(stock_overview.etc.taghir_malekiat_hoghoghi) }}</div> <span style="font-size: 12px;color: #666;">{{ intToString(stock_overview.etc.taghir_malekiat_hoghoghi) }}</span></div>
                            </div>
                        </div>
                    </div>
                </li>

                <li v-if="!hide_demands" class="li-head" style="border-top: 0;">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">عرضه و تقاضا</div>
                        <div class="profile-inner-table-actions actions-min-w"></div>
                    </div>
                </li>
                
                <li :class="activeLi == 103 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(103)">
                        <div class="profile-inner-table-name">عرضه و تقاضا</div>
                        <div class="profile-inner-table-value"></div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="tabs-contents w100-w stock-summary-demands" >
                        <div v-if="!hide_demands && activeLi == 103" class="tabs-content active">
                            <div class="fs-row bootstrap-fix widgets">
                                <div class="row tgju-widgets-row">
                                    <div class="tgju-widgets-block col-12 col-lg-6 col-xl-4" style="padding: 0 !important;">
                                        <div class="tgju-widget light has-icon ask-and-bid-widget">
                                            <div class="tables-default normal tables-border-bt-none">
                                                <table class="table table-hover mini-mobile-table text-center stocks-deals-table">
                                                    <thead class="text-center">
                                                        <tr>
                                                            <th>دستور</th>
                                                            <th>تعداد</th>
                                                            <th>خرید</th>
                                                            <th>فروش</th>
                                                            <th>تعداد</th>
                                                            <th>دستور</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td class="color-green"><span class="mobile-title">دستور</span><span dir="ltr"
                                                                    data-id="1">{{ formatPrice(stock_overview.demands[1][1]) }}</span></td>
                                                            <td class="color-green"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                                    data-id="0">{{ formatPrice(stock_overview.demands[1][0]) }}</span></td>
                                                            <td class="color-green"><span class="mobile-title">خرید</span><span dir="ltr"
                                                                    data-id="2">{{ formatPrice(stock_overview.demands[1][2]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">فروش</span><span dir="ltr"
                                                                    data-id="3">{{ formatPrice(stock_overview.demands[1][3]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                                    data-id="5">{{ formatPrice(stock_overview.demands[1][5]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">دستور</span><span dir="ltr"
                                                                    data-id="4">{{ formatPrice(stock_overview.demands[1][4]) }}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class="color-green"><span class="mobile-title">دستور</span><span dir="ltr"
                                                                    data-id="1">{{ formatPrice(stock_overview.demands[2][1]) }}</span></td>
                                                            <td class="color-green"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                                    data-id="0">{{ formatPrice(stock_overview.demands[2][0]) }}</span></td>
                                                            <td class="color-green"><span class="mobile-title">خرید</span><span dir="ltr"
                                                                    data-id="2">{{ formatPrice(stock_overview.demands[2][2]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">فروش</span><span dir="ltr"
                                                                    data-id="3">{{ formatPrice(stock_overview.demands[2][3]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                                    data-id="5">{{ formatPrice(stock_overview.demands[2][5]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">دستور</span><span dir="ltr"
                                                                    data-id="4">{{ formatPrice(stock_overview.demands[2][4]) }}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class="color-green"><span class="mobile-title">دستور</span><span dir="ltr"
                                                                    data-id="1">{{ formatPrice(stock_overview.demands[3][1]) }}</span></td>
                                                            <td class="color-green"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                                    data-id="0">{{ formatPrice(stock_overview.demands[3][0]) }}</span></td>
                                                            <td class="color-green"><span class="mobile-title">خرید</span><span dir="ltr"
                                                                    data-id="2">{{ formatPrice(stock_overview.demands[3][2]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">فروش</span><span dir="ltr"
                                                                    data-id="3">{{ formatPrice(stock_overview.demands[3][3]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                                    data-id="5">{{ formatPrice(stock_overview.demands[3][5]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">دستور</span><span dir="ltr"
                                                                    data-id="4">{{ formatPrice(stock_overview.demands[3][4]) }}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class="color-green"><span class="mobile-title">دستور</span><span dir="ltr"
                                                                    data-id="1">{{ formatPrice(stock_overview.demands[4][1]) }}</span></td>
                                                            <td class="color-green"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                                    data-id="0">{{ formatPrice(stock_overview.demands[4][0]) }}</span></td>
                                                            <td class="color-green"><span class="mobile-title">خرید</span><span dir="ltr"
                                                                    data-id="2">{{ formatPrice(stock_overview.demands[4][2]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">فروش</span><span dir="ltr"
                                                                    data-id="3">{{ formatPrice(stock_overview.demands[4][3]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                                    data-id="5">{{ formatPrice(stock_overview.demands[4][5]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">دستور</span><span dir="ltr"
                                                                    data-id="4">{{ formatPrice(stock_overview.demands[4][4]) }}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td class="color-green"><span class="mobile-title">دستور</span><span dir="ltr"
                                                                    data-id="1">{{ formatPrice(stock_overview.demands[5][1]) }}</span></td>
                                                            <td class="color-green"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                                    data-id="0">{{ formatPrice(stock_overview.demands[5][0]) }}</span></td>
                                                            <td class="color-green"><span class="mobile-title">خرید</span><span dir="ltr"
                                                                    data-id="2">{{ formatPrice(stock_overview.demands[5][2]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">فروش</span><span dir="ltr"
                                                                    data-id="3">{{ formatPrice(stock_overview.demands[5][3]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">تعداد</span><span dir="ltr"
                                                                    data-id="5">{{ formatPrice(stock_overview.demands[5][5]) }}</span></td>
                                                            <td class="color-red"><span class="mobile-title">دستور</span><span dir="ltr"
                                                                    data-id="4">{{ formatPrice(stock_overview.demands[5][4]) }}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">خصیصه</div>
                        <div class="profile-inner-table-value"></div>
                        <div class="profile-inner-table-actions actions-min-w"></div>
                    </div>
                </li>

                <li :class="activeLi == 104 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(104)">
                        <div class="profile-inner-table-name">EPS</div>
                        <div class="profile-inner-table-value"></div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">سال مالی</div>
                                <div class="profile-inner-table-value">{{ Object.keys(stock_overview.etc.eps_data).length > 0 ? to_yyyy_mm_dd(stock_overview.etc.eps_data[Object.keys(stock_overview.etc.eps_data)[0]].sal_mali) : '-' }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">تحقق سود</div>
                                <div class="profile-inner-table-value">
                                    <div style="display: inline-block;direction: ltr;" :class="( getNumMinus(Object.keys(stock_overview.etc.eps_data).length > 0 ? stock_overview.etc.eps_data[Object.keys(stock_overview.etc.eps_data)[0]].tahaghogh_sood : 0) ? ' low': '')">
                                        {{ Object.keys(stock_overview.etc.eps_data).length > 0 ? formatPrice(stock_overview.etc.eps_data[Object.keys(stock_overview.etc.eps_data)[0]].tahaghogh_sood) : '-' }} 
                                    </div>
                                    دوره {{ Object.keys(stock_overview.etc.eps_data).length > 0 ? stock_overview.etc.eps_data[Object.keys(stock_overview.etc.eps_data)[0]].period : '-' }}
                                </div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">سود TTM</div>
                                <div style="display: inline-block;direction: ltr;" :class="'profile-inner-table-value' + ( getNumMinus(Object.keys(stock_overview.etc.eps_data).length > 0 ? stock_overview.etc.eps_data[Object.keys(stock_overview.etc.eps_data)[0]].sood_ttm : 0) ? ' low': '')">
                                    {{ Object.keys(stock_overview.etc.eps_data).length > 0 ? formatPrice(stock_overview.etc.eps_data[Object.keys(stock_overview.etc.eps_data)[0]].sood_ttm) : '-' }}
                                </div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">P/E TTM</div>
                                <div style="display: inline-block;direction: ltr;" :class="'profile-inner-table-value' + ( getNumMinus(Object.keys(stock_overview.etc.eps_data).length > 0 ? stock_overview.etc.eps_data[Object.keys(stock_overview.etc.eps_data)[0]].pe_ttm : 0) ? ' low': '')">
                                    {{ Object.keys(stock_overview.etc.eps_data).length > 0 ? stock_overview.etc.eps_data[Object.keys(stock_overview.etc.eps_data)[0]].pe_ttm : '-' }}
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li :class="activeLi == 105 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(105)">
                        <div class="profile-inner-table-name">DPS</div>
                        <div class="profile-inner-table-value"></div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>

                    <!-- <div style="display: inline-block;direction: ltr;" :class="( getNumMinus(stock_overview.etc.eps_data[Object.keys(stock_overview.etc.eps_data)[0]].tahaghogh_sood) ? ' low': '')">
                        {{ stock_overview.etc.eps_data ? stock_overview.etc.eps_data[Object.keys(stock_overview.etc.eps_data)[0]].tahaghogh_sood : '' }} 
                    </div> -->


                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">آخرین مجمع</div>
                                <div class="profile-inner-table-value">{{ Object.keys(stock_overview.etc.dps_data).length > 0 ? to_yyyy_mm_dd(stock_overview.etc.dps_data[Object.keys(stock_overview.etc.dps_data)[0]].akharin_majma) : '-' }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">سود واقعی</div>
                                <div style="direction: ltr;" :class="'profile-inner-table-value' + ( getNumMinus(Object.keys(stock_overview.etc.dps_data).length > 0 ? stock_overview.etc.dps_data[Object.keys(stock_overview.etc.dps_data)[0]].sood_vaghei : 0) ? ' low': '')">{{ Object.keys(stock_overview.etc.dps_data).length > 0 ?  formatPrice(stock_overview.etc.dps_data[Object.keys(stock_overview.etc.dps_data)[0]].sood_vaghei) : '-' }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">P/E مجمع</div>
                                <div style="direction: ltr;" :class="'profile-inner-table-value' + ( getNumMinus(Object.keys(stock_overview.etc.dps_data).length > 0 ? stock_overview.etc.dps_data[Object.keys(stock_overview.etc.dps_data)[0]].majma_pe : 0) ? ' low': '')">{{ Object.keys(stock_overview.etc.dps_data).length > 0 ?  stock_overview.etc.dps_data[Object.keys(stock_overview.etc.dps_data)[0]].majma_pe : '-' }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">سود نقدی</div>
                                <div style="direction: ltr;" :class="'profile-inner-table-value' + ( getNumMinus(Object.keys(stock_overview.etc.dps_data).length > 0 ? stock_overview.etc.dps_data[Object.keys(stock_overview.etc.dps_data)[0]].sood_naghdi : 0) ? ' low': '')">{{ Object.keys(stock_overview.etc.dps_data).length > 0 ?  formatPrice(stock_overview.etc.dps_data[Object.keys(stock_overview.etc.dps_data)[0]].sood_naghdi) : '-' }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">درصد توزیع</div>
                                <div style="direction: ltr;" :class="'profile-inner-table-value' + ( getNumMinus(Object.keys(stock_overview.etc.dps_data).length > 0 ? stock_overview.etc.dps_data[Object.keys(stock_overview.etc.dps_data)[0]].darsad_tozi : 0) ? ' low': '')">{{ Object.keys(stock_overview.etc.dps_data).length > 0 ? stock_overview.etc.dps_data[Object.keys(stock_overview.etc.dps_data)[0]].darsad_tozi : '-' }} %</div>
                            </div>
                        </div>
                    </div>
                </li>

                <li :class="activeLi == 106 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(106)">
                        <div class="profile-inner-table-name">بازدهی و نقدشوندگی</div>
                        <div class="profile-inner-table-value"></div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">3 ماهه</div>
                                <div class="profile-inner-table-value">
                                    <div style="display: inline-block;direction: ltr;" :class="( getNumMinus(stock_overview.etc.bazdehi_data.length > 0 ? stock_overview.etc.bazdehi_data['3_months'].percentage : 0) ? ' low': '')">
                                        {{ stock_overview.etc.bazdehi_data.length > 0 ? stock_overview.etc.bazdehi_data["3_months"].percentage : '-'}} 
                                    </div>
                                    ( رتبه  {{ stock_overview.etc.bazdehi_data.length > 0 ? stock_overview.etc.bazdehi_data["3_months"].rate : '-'}} )
                                </div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">6 ماهه</div>
                                <div class="profile-inner-table-value">
                                    <div style="display: inline-block;direction: ltr;" :class="( getNumMinus(stock_overview.etc.bazdehi_data.length > 0 ? stock_overview.etc.bazdehi_data['6_months'].percentage : 0) ? ' low': '')">
                                        {{ stock_overview.etc.bazdehi_data.length > 0 ? stock_overview.etc.bazdehi_data["6_months"].percentage : '-'}} 
                                    </div>
                                    ( رتبه  {{ stock_overview.etc.bazdehi_data.length > 0 ? stock_overview.etc.bazdehi_data["6_months"].rate : '-'}} )
                                </div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">9 ماهه</div>
                                <div class="profile-inner-table-value">
                                    <div style="display: inline-block;direction: ltr;" :class="( getNumMinus(stock_overview.etc.bazdehi_data.length > 0 ? stock_overview.etc.bazdehi_data['9_months'].percentage : 0) ? ' low': '')">
                                        {{ stock_overview.etc.bazdehi_data.length > 0 ? stock_overview.etc.bazdehi_data["9_months"].percentage : '-'}} 
                                    </div>
                                    ( رتبه  {{ stock_overview.etc.bazdehi_data.length > 0 ? stock_overview.etc.bazdehi_data["9_months"].rate : '-'}} )
                                </div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">1 ساله</div>
                                <div class="profile-inner-table-value">
                                    <div style="display: inline-block;direction: ltr;" :class="( getNumMinus(stock_overview.etc.bazdehi_data.length > 0 ? stock_overview.etc.bazdehi_data['1_year'].percentage : 0) ? ' low': '')">
                                        {{ stock_overview.etc.bazdehi_data.length > 0 ? stock_overview.etc.bazdehi_data["1_year"].percentage : '-'}} 
                                    </div>
                                    ( رتبه  {{ stock_overview.etc.bazdehi_data.length > 0 ? stock_overview.etc.bazdehi_data["1_year"].rate : '-'}} )
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                
                <li class="active-current">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">ارزش بازار</div>
                        <div :class="'profile-inner-table-value' + ( getNumMinus(stock_overview.trades.arzesh_bazar) ? ' low': '')">{{ roundInt(stock_overview.trades.arzesh_bazar) }} <span style="font-size: 12px;color: #666;">{{ intToString(stock_overview.trades.arzesh_bazar) }}</span></div>
                    </div>
                </li>
                
                <li class="active-current">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">P/B</div>
                        <div style="direction: ltr;" :class="'profile-inner-table-value' + ( getNumMinus(stock_overview.info.pb) ? ' low': '')">{{ stock_overview.info.pb }}</div>
                    </div>
                </li>
                
                <li class="active-current">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">سهام شناور آزاد</div>
                        <div style="direction: ltr;" :class="'profile-inner-table-value' + ( getNumMinus(stock_overview.info.shares) ? ' low': '')">{{ stock_overview.info.shares }} %</div>
                    </div>
                </li>

                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">تاریخچه</div>
                        <div class="profile-inner-table-value">تاریخ</div>
                        <div class="profile-inner-table-actions actions-min-w" style="min-width: 18px;"></div>
                    </div>
                </li>

                <li v-for="(item, index) in stock_history"  v-if="stock_history" :key="index" :class="activeLi == index+10 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(index+10)">
                        <div class="profile-inner-table-name">تاریخ</div>
                        <div class="profile-inner-table-value">{{ toRelative(item.last_date) }}</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>

                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">پایانی</div>
                                <div class="profile-inner-table-value">{{ formatPrice(item.PClosing) }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">درصد تغییر</div>
                                <div :class="'profile-inner-table-value' + ( getStringNumMinus(item.closing_change_percentage) )">{{ item.closing_change_percentage.length > 1 ? formatPrice(item.closing_change_percentage).replace('-', '') : formatPrice(item.closing_change_percentage)}} %</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">حجم</div>
                                <div class="profile-inner-table-value">{{ roundInt(item.QTotTran5J) }}<span style="font-size: 12px;color: #666;"> {{ intToString(item.QTotTran5J) }}</span></div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">دفعات</div>
                                <div class="profile-inner-table-value">{{ formatPrice(item.ZTotTran) }}</div>
                            </div>
                        </div> 
                    </div>
                </li>

                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">نماد‌های هم گروه</div>
                        <div class="profile-inner-table-value">عنوان نماد</div>
                        <div class="profile-inner-table-actions actions-min-w" style="min-width: 18px;"></div>
                    </div>
                </li>
                <li v-for="(item, index) in stock_related_instruments"  v-if="stock_related_instruments" :key="index" :class="activeLi == index+20 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(index+20)">
                        <div class="profile-inner-table-name">نماد</div>
                        <div class="profile-inner-table-value">{{ item.symbol }}</div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>

                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">آخرین</div>
                                <div class="profile-inner-table-value">{{ formatPrice(item.PDrCotVal) }}</div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">حجم</div>
                                <div class="profile-inner-table-value">{{ roundInt(item.QTotTran5J) }}<span style="font-size: 12px;color: #666;"> {{ intToString(item.QTotTran5J) }}</span></div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">ارزش بازار</div>
                                <div class="profile-inner-table-value">{{ roundInt(item.arzesh_bazar) }}<span style="font-size: 12px;color: #666;"> {{ intToString(item.arzesh_bazar) }}</span></div>
                            </div>
                        </div>
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">تاریخ</div>
                                <div class="profile-inner-table-value">{{ item.time }}</div>
                            </div>
                        </div> 
                    </div>
                </li>

                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">اطلاعیه‌ها</div>
                        <div class="profile-inner-table-value"></div>
                        <div class="profile-inner-table-actions actions-min-w"></div>
                    </div>
                </li>
                <li v-for="(item, index) in stock_codal" v-if="stock_codal" :key="index" :class="activeLi == index+40 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(index+40)">
                        <div class="profile-inner-table-name">{{ item.title }} <br> {{ item.sent_datetime}}
                        <br>
                        <a :href="item.attachment_url" target="_blank" v-if="item.has_attachment">ضمیمه</a> | 
                        <a :href="item.excel_url" target="_blank" v-if="item.excel_url">excel</a> | 
                        <a :href="item.pdf_url" target="_blank" v-if="item.pdf_url">pdf</a> | 
                        <a :href="item.html_url" target="_blank" v-if="item.html_url">فرمت</a>  
                        </div>
                        <div class="profile-inner-table-value"></div>
                    </div>
                </li>

                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">پیام ناظر</div>
                        <div class="profile-inner-table-value"></div>
                        <div class="profile-inner-table-actions actions-min-w"></div>
                    </div>
                </li>
                <li v-for="(item, index) in stock_message" v-if="stock_message" :key="index" :class="activeLi == index+30 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(index+30)">
                        <div class="profile-inner-table-name">{{ item.title }} <br> {{ toRelative(item.created_at) }}</div>
                        <div class="profile-inner-table-value"></div>
                    </div>
                </li>

            </ul>
        </div>

    </slot>
</div>
<div v-else>
    <slot v-if="widgetLoad">
        <div class="text-center p-2 w100-w">
            <div class="widget-loading"></div>
        </div>
    </slot>
    <slot v-else>
        <div class="market-profile-inner">
            <div class="profile-inner-filter-row">
                <div class="filter-row-name">
                    <i class="fa fa-clock-o" aria-hidden="true"></i> {{stock_overview.info.time}}
                </div>
            </div>
            <ul class="m-pointer" style="margin-bottom: 0;">
                <li class="li-head">
                    <div class="profile-inner-table-row">
                        <div class="profile-inner-table-name">خصیصه</div>
                        <div class="profile-inner-table-value">مقدار</div>
                        <div class="profile-inner-table-actions actions-min-w"></div>
                    </div>
                </li>

                <li :class="activeLi == 100 ? 'active-current' : ''">
                    <div class="profile-inner-table-row" @click="setActive(100)">
                        <div class="profile-inner-table-name">آخرین معامله</div>
                        <div class="profile-inner-table-value"></div>
                        <div class="profile-inner-table-actions actions-min-w"><i class="uil uil-angle-down"></i></div>
                    </div>
                    <div class="profile-inner-table-box">
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">بازگشایی</div>
                                <div class="profile-inner-table-value" data-col="info.PriceFirst"  data-val="2164">{{ roundInt(stock_overview.info.open) }}<span style="font-size: 12px;color: #666;"> {{ intToString(stock_overview.info.open) }}</span></div>
                            </div>
                        </div>  
                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">پایانی</div>
                                <div class="profile-inner-table-value" data-col="info.last_price.PClosing" data-val="2158">{{ roundInt(stock_overview.info.value) }}<span style="font-size: 12px;color: #666;"> {{ intToString(stock_overview.info.value) }}</span></div>
                            </div>
                        </div>

                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">بیشترین</div>
                                <div class="profile-inner-table-value high" data-col="info.day_range.PriceMax">{{ roundInt(stock_overview.info.max) }}<span style="font-size: 12px;color: #666;"> {{ intToString(stock_overview.info.max) }}</span></div>
                            </div>
                        </div>

                        <div class="profile-inner-table-item">
                            <div class="profile-inner-table-row">
                                <div class="profile-inner-table-name">کمترین</div>
                                <div class="profile-inner-table-value low" data-col="info.day_range.PriceMax">{{ roundInt(stock_overview.info.min) }}<span style="font-size: 12px;color: #666;"> {{ intToString(stock_overview.info.min) }}</span></div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </slot>
</div>
</template>

<script>
// این کامپوننت دارای اجزای بخش صفحه اول نمایش پروفایل شاخص در بازار ها می باشد
import moment from "moment-jalaali";

export default {
    name: 'StockOverview',
    props: ['data'],
    data() {
        return {
            stock_overview: null,
            stock_codal: null,
            stock_history: null,
            stock_related_instruments: null,
            stock_message: null,
            activeLi: 100,
            hide_demands: false,
            widgetLoad: true,
        }
    },
    mounted() {
        this.getProfileOverview();
    },
    methods: {
        // نمایش زمان بصورت 1401/03/12
        to_yyyy_mm_dd(date) {
            return moment(date).format("jYYYY/jMM/jDD");
        },
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).format("jYYYY/jMM/jDD - HH:mm");
        },
        getStringNumMinus(number){
            if (number == 0) {
                return '';
            }
            return number < 0 ? ' low' : ' high';
        },
        getNumMinus(number){
            return number < 0 ? true : false;
        },
        getProfileOverview() {
            this.$helpers
                .makeRequest("GET", `/stock/profile-overview/${this.$route.params.name}`)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.stock_overview = api_response.data.response.detail.body.response;
                        this.stock_codal = api_response.data.response.codal.body.response.items.data;
                        this.stock_history = api_response.data.response.history.body.response.items;
                        this.stock_related_instruments = api_response.data.response.related_instruments.body.response.items;
                        this.stock_message = api_response.data.response.message.body.response.items.data;
                        this.widgetLoad = false;
                        // this.stock_header_summary = api_response.data.response.detail.response.summary;
                        // $('.stocks-profile').append('<link rel="stylesheet" type="text/css" href="https://static.tgju.org/components/tgju-fix/fix-custom.css">');
                        // $('body').addClass('internal profile fs-grid mprofile');
                    }
                });
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            if (!value) {
                // this.hide_demands = true;
                return '-';
            }
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        intToString(value) {
            if (value === null) return '';

            if ((value).length > 12) {
                return 'هزار میلیارد';
            } else if ((value).length > 9) {
                return 'میلیارد';
            } else if ((value).length > 6) {
                return 'میلیون';
            } else if ((value).length > 3) {
                return '';
            } else {
                return '';
            }
        },
        roundInt(value) {
            if (value === null) return 0;

            if ((value).length > 12) {
                return parseFloat(parseFloat(value / Math.pow(1000,4)).toFixed(3));
            } else if ((value).length > 9) {
                return parseFloat(parseFloat(value / Math.pow(1000,3)).toFixed(3));
            } else if ((value).length > 6) {
                return parseFloat(parseFloat(value / Math.pow(1000,2)).toFixed(3));
            } else if ((value).length > 3) {
                return this.formatPrice(value);
            } else {
                return value;
            }
        },
        setActive(index){
            if(index == this.activeLi){
                this.activeLi = null;
            }else{
                this.activeLi = index;
            }
        },
    },
}
</script>

<style>

</style>